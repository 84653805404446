import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

export default function EBCompnaiesNav() {
  const items = useMemo(() => {
    const navItems = [
      {
        name: "Company List",
        to: "eb-company-list",
      },
      {
        name: "InvexERP Testers",
        to: "eb-company-testers",
      },
      {
        name: " AI Data",
        to: "ai-data-label",
      },
    ];

    return navItems;
  });

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            {item?.type !== undefined ? (
              <a className={`nav-link ga-nav-tab`} href={item.to}>
                {item.name}
              </a>
            ) : (
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
