import { FieldArray, useFormik, FormikProvider } from "formik";
import React, { useState, useMemo, useEffect } from "react";
import Button from "react-bootstrap/Button";
import {
  Modal,
  Form,
  Nav,
  InputGroup,
  Row,
  Col,
  Accordion,
} from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import useDebounce, {
  useBackendUrl,
  useCurrencies,
  useEffectOnce,
  useIsAdmin,
  useIsDocumentControl,
} from "../utils/hooks";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useMutation, useQuery } from "react-query";
import { amountTypes, initialGeneralSettings } from "../utils/helpers";
import {
  GearIcon,
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  DropdownCloseIcon,
  TrashIcon,
} from "./Icons";
import { nanoid } from "../utils/helpers";
import Select from "react-select";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import eventBus from "../utils/EventBus";
import { isEmpty, uniqBy } from "lodash";
import { cacheTimes, queryActions } from "../utils/reactQueryActions";
import NumberCustomInput from "./utils/NumberCustomInput";
import { backDateDurationOptions, durationOptions } from "../config";
import ConfirmDialog from "./ConfirmDialogue";
import ModalLoader from "./utils/ModalLoader";
import DisplayChildElement from "./DisplayChildElement";
import { currenciesOptionsAll } from "../utils/currencies";

import DateTime from "react-datetime";

const itemSearchCriteriaOptions = [
  {
    label: "Item Code",
    value: "Item Code",
  },
  {
    label: "Serial Number",
    value: "Serial Number",
  },
];
const procurementPlantTypeOptions = [
  {
    label: "Default Plan",
    value: "Default Plan",
  },
  {
    label: "Custom Plan",
    value: "Custom Plan",
  },
];

const inventoryManagementOptions = [
  {
    label: "FIFO",
    value: "FIFO",
  },
  {
    label: "LIFO",
    value: "LIFO",
  },
  {
    label: "FEFO",
    value: "FEFO",
  },
];

const limitOptions = [
  {
    label: "Fixed",
    value: "fixed",
  },
  {
    label: "Any Amount",
    value: "any",
  },
];

const sortByArchive = [
  {
    label: "Show All",
    value: "Show All",
  },
  {
    label: "Archived",
    value: "Archived",
  },
  {
    label: "Not Archived",
    Value: "Not Archived",
  },
];

export function StaffSelectorMultiple({
  data,
  index,
  name,
  formik,
  arrayHelpers,
  hasLimit,
  disabled = false,
  isRequester = false,
}) {
  const staffSelectOptions = useMemo(() => {
    const jobGrades = data.staffs
      .filter((el) => el.Department === formik.values[name][index].department)
      .map((el) => ({ jobGrade: el.jobGrade }));

    /*    const staffs = data.staffs.filter(
      (el) =>
        el.Department === formik.values[name][index].department &&
        el.jobGrade === formik.values[name][index].jobGrade
    ); */

    const staffs = data.staffs.filter((el) => {
      if (!formik.values[name][index].department) return true;
      if (
        !formik.values[name][index].department &&
        !formik.values[name][index].jobGrade
      )
        return true;
      if (
        formik.values[name][index].department &&
        !formik.values[name][index].jobGrade
      ) {
        return el.Department === formik.values[name][index].department;
      }
      if (
        formik.values[name][index].department &&
        formik.values[name][index].jobGrade
      ) {
        return (
          el.Department === formik.values[name][index].department &&
          el.jobGrade === formik.values[name][index].jobGrade
        );
      }
    });

    return {
      departments: [
        { label: "Any Department", value: "" },
        { label: "Department Lead", value: "Department Lead" },
        ...data.departments.map((el) => ({
          label: el.Department,
          value: el.Department,
        })),
      ],
      jobGrades: [
        { label: "Any Job Grade", value: "" },
        ...uniqBy(jobGrades, "jobGrade").map((el) => ({
          label: el.jobGrade,
          value: el.jobGrade,
        })),
      ],
      staffs: [
        { label: "Any Staff", value: "" },
        ...staffs.map((el) => ({
          ...el,
          label: el.Name,
          value: el.Staff_ID,
        })),
      ],
    };
  }, [data, name, index, formik.values]);

  return (
    <div className="col-md-6">
      <div className="d-flex justify-content-between mt-3">
        {isRequester ? <p className="fw-bold h6">Requester</p> : <span />}
        <Button
          title="Remove"
          variant="outline-danger"
          type="button"
          onClick={() => arrayHelpers.remove(index)}
          disabled={disabled}
        >
          ✖
        </Button>
      </div>
      <Form.Group className="mb-3">
        <Form.Label>Department :</Form.Label>
        <Select
          classNamePrefix={`form-select`}
          name={`${name}.${index}.department`}
          options={staffSelectOptions?.departments}
          value={staffSelectOptions?.departments.find(
            (el) => el.value === formik.values[name][index].department
          )}
          onChange={(selected) => {
            formik.setFieldValue(
              `${name}.${index}.department`,
              selected?.value || ""
            );

            // Clear others
            formik.setFieldValue(`${name}.${index}.jobGrade`, "");
            formik.setFieldValue(`${name}.${index}.staff`, "");
            formik.setFieldValue(`${name}.${index}.staffName`, "");
          }}
          isDisabled={disabled}
          isClearable
        />
      </Form.Group>

      {formik.values[name][index].department !== "Department Lead" && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Job Grade :</Form.Label>
            <Select
              classNamePrefix={`form-select`}
              name={`${name}.${index}.jobGrade`}
              options={staffSelectOptions?.jobGrades}
              value={staffSelectOptions?.jobGrades.find(
                (el) => el.value === formik.values[name][index].jobGrade
              )}
              onChange={(selected) =>
                formik.setFieldValue(
                  `${name}.${index}.jobGrade`,
                  selected?.value || ""
                )
              }
              isDisabled={disabled}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Recipient :</Form.Label>
            <Select
              classNamePrefix={`form-select`}
              name={`${name}.${index}.staff`}
              options={staffSelectOptions?.staffs}
              value={staffSelectOptions?.staffs.find(
                (el) => el.value === formik.values[name][index].staff
              )}
              onChange={(selected) => {
                formik.setFieldValue(
                  `${name}.${index}.staff`,
                  selected?.value || ""
                );
                formik.setFieldValue(
                  `${name}.${index}.staffName`,
                  selected?.Name || ""
                );
                formik.setFieldValue(
                  `${name}.${index}.department`,
                  selected?.Department || ""
                );
              }}
              // isDisabled={isFetching}
              isDisabled={disabled}
            />
          </Form.Group>
        </>
      )}
      {hasLimit && (
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Limit Type:</Form.Label>
              <Select
                options={limitOptions}
                value={limitOptions?.find(
                  (el) => el.value === formik.values[name][index].limitType
                )}
                onChange={({ value }) =>
                  formik.setFieldValue(`${name}.${index}.limitType`, value)
                }
              />{" "}
            </Form.Group>
          </Col>{" "}
          <Col md={6}>
            {formik.values[name][index].limitType !== "any" && (
              <Form.Group className="mb-3">
                <Form.Label>Limit:</Form.Label>
                <CurrencyCustomInput
                  symbol=""
                  name={`${name}.${index}.limit`}
                  value={formik.values[name][index].limit}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}

function MandatorySignatorySettings({ formik }) {
  const { user: authUser, backendUrl } = useAuth();

  const getDepartmentsForForm = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS],
    () => getDepartmentsForForm(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  return (
    <>
      <div>
        <hr />
        <h2 className="mb-3 h5">Signatory Settings</h2>

        <div>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              <h2 className="mb-3 h6">Fund Request</h2>

              <Form.Group className="mb-3 col-6">
                <Form.Label>
                  Number of Mandatory Signatories before Approve & Close
                </Form.Label>
                <NumberCustomInput
                  name="numberOfMandatoryApprovalsBeforeApproveAndClose"
                  value={
                    formik.values
                      .numberOfMandatoryApprovalsBeforeApproveAndClose
                  }
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>

              <FieldArray
                name="fundRequestMandatorySignatory"
                render={(arrayHelpers) => (
                  <>
                    <div className="row">
                      {formik.values.fundRequestMandatorySignatory.map(
                        (el, index) => (
                          <>
                            <StaffSelectorMultiple
                              data={data}
                              name="fundRequestMandatorySignatory"
                              index={index}
                              formik={formik}
                              arrayHelpers={arrayHelpers}
                              hasLimit={true}
                            />
                          </>
                        )
                      )}
                    </div>
                    <div className="d-flex justify-content-start px-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-nowrap"
                        onClick={() =>
                          arrayHelpers.push({
                            department: "",
                            jobGrade: "",
                            staff: "",
                          })
                        }
                      >
                        + Add
                      </button>
                    </div>
                  </>
                )}
              />

              <Accordion className="mt-3" defaultActiveKey="0" flush>
                <Accordion.Item eventKey="1" className="border">
                  <Accordion.Header>
                    <h2 className="mb-0 h6">
                      Second line Fund Request Approval
                    </h2>
                  </Accordion.Header>
                  <Accordion.Body>
                    <FieldArray
                      name="secondLineFundRequestApproval"
                      render={(arrayHelpers) => (
                        <>
                          <div className="row">
                            {formik.values.secondLineFundRequestApproval.map(
                              (el, index) => (
                                <>
                                  <StaffSelectorMultiple
                                    data={data}
                                    name="secondLineFundRequestApproval"
                                    index={index}
                                    formik={formik}
                                    arrayHelpers={arrayHelpers}
                                    hasLimit={true}
                                  />
                                </>
                              )
                            )}
                          </div>
                          <div className="d-flex justify-content-start px-4">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary text-nowrap"
                              onClick={() =>
                                arrayHelpers.push({
                                  department: "",
                                  jobGrade: "",
                                  staff: "",
                                })
                              }
                            >
                              + Add
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <hr />

              <h2 className="mb-3 h6">Material Request</h2>
              <FieldArray
                name="materialRequestMandatorySignatory"
                render={(arrayHelpers) => (
                  <>
                    <div className="row">
                      {formik.values.materialRequestMandatorySignatory.map(
                        (el, index) => (
                          <>
                            <StaffSelectorMultiple
                              data={data}
                              name="materialRequestMandatorySignatory"
                              index={index}
                              formik={formik}
                              arrayHelpers={arrayHelpers}
                            />
                          </>
                        )
                      )}
                    </div>
                    <div className="d-flex justify-content-start px-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-nowrap"
                        onClick={() =>
                          arrayHelpers.push({
                            department: "",
                            jobGrade: "",
                            staff: "",
                          })
                        }
                      >
                        + Add
                      </button>
                    </div>
                  </>
                )}
              />

              <hr />
              <h2 className="mb-3 h6">Allowance</h2>
              <FieldArray
                name="allowanceMandatorySignatory"
                render={(arrayHelpers) => (
                  <>
                    <div className="row">
                      {formik.values.allowanceMandatorySignatory.map(
                        (el, index) => (
                          <>
                            <StaffSelectorMultiple
                              data={data}
                              name="allowanceMandatorySignatory"
                              index={index}
                              formik={formik}
                              arrayHelpers={arrayHelpers}
                              hasLimit={true}
                            />
                          </>
                        )
                      )}
                    </div>
                    <div className="d-flex justify-content-start px-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-nowrap"
                        onClick={() =>
                          arrayHelpers.push({
                            department: "",
                            jobGrade: "",
                            staff: "",
                          })
                        }
                      >
                        + Add
                      </button>
                    </div>
                  </>
                )}
              />
            </Form>
          </FormikProvider>
        </div>
      </div>
    </>
  );
}

export default function GeneralSettingsModal({
  showGeneralSettingsModal,
  setShowGeneralSettingsModal,
}) {
  const paymentMethod = [
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit",
      value: "Credit",
    },
    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
    {
      icon: <CustomerAccountSelectIcon />,
      label: "Customer Account",
      value: "Customer Account",
    },
    /*{
      icon: <CashSelectIcon />,
      label: "Loyalty Account",
      value: "Loyalty Account",
    },*/
    {
      icon: <CreditMemoSelectIcon />,
      label: "Credit Memo",
      value: "Credit Memo",
    },
    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CashSelectIcon />,
      label: "Split Payment",
      value: "Split Payment",
    },
    {
      icon: <CashSelectIcon />,
      label: "Split Payment Multiple",
      value: "Split Payment Multiple",
    },
  ];

  const [searchText, setSearchText] = useState("");
  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  const isDocumentControl = useIsDocumentControl();
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const [
    showJournalIncentiveAccountPopover,
    setShowJournalIncentiveAccountPopover,
  ] = useState(false);
  const [key, setkey] = useState();

  const debounceSearchText = useDebounce(searchText, 500);

  const addSettings = async (payload) => {
    let response = await fetch(`${backendUrl}/api/users/company-settings`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addSettingsMutation = useMutation((payload) => addSettings(payload), {
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: initialGeneralSettings,
    onSubmit: (values) => {
      //  Save  to  server

      values = {
        ...values,
        expiredItemsRecipientEmail: JSON.stringify(
          values?.expiredItemsRecipientEmail
        ),
        insuranceCreditLines: JSON.stringify(values?.insuranceCreditLines),
        waybillSignatories: JSON.stringify(values?.waybillSignatories),
      };
      addSettingsMutation.mutate(
        { settings: values },
        {
          onSuccess: ({ message, data }) => {
            setGeneralSettings(values);
            toast.success("Settings saved");
            setShowGeneralSettingsModal(false);
          },
        }
      );
    },
  });

  const setUp = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/company/settings`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let { data } = await response.json();
    let settings;
    let settingsWaybill;

    if (data?.settings?.insuranceCreditLines) {
      let insuranceCreditLines = JSON.parse(
        data?.settings?.insuranceCreditLines
      );
      data.settings.insuranceCreditLines = insuranceCreditLines;
    } else {
      data.settings.insuranceCreditLines = [{ creditLine: "" }];
    }

    if (data?.settings?.expiredItemsRecipientEmail) {
      let expiredItemRecipient = JSON.parse(
        data?.settings?.expiredItemsRecipientEmail
      );

      settings = {
        expiredItemsRecipientEmail: expiredItemRecipient,
      };
    } else {
      settings = {
        expiredItemsRecipientEmail: [{ email: "" }],
      };
    }
    //
    if (data?.settings?.waybillSignatories) {
      let expiredItemRecipient = JSON.parse(data?.settings?.waybillSignatories);

      settingsWaybill = {
        waybillSignatories: expiredItemRecipient,
      };
    } else {
      settingsWaybill = {
        waybillSignatories: [{ name: "" }],
      };
    }

    data = { ...data?.settings, ...settings, ...settingsWaybill };
    formik.setValues({ ...initialGeneralSettings, ...data });

    if (data.staff) {
      data.staff = data.staff.map((el) => ({
        ...el,
        label: el.Name,
        value: el.Staff_ID,
      }));
    }

    return data;
  };

  const getUsersInSystem = async () => {
    let response = await fetch(`${backendUrl}/api/users`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    data.allUsersInSystem = [
      {
        label: "None",
        value: "",
      },
      ...data.staff.map((el) => ({
        label: el.Name,
        value: el.Staff_ID,
      })),
    ];

    return data;
  };
  const {
    data: { allUsersInSystem } = { allUsersInSystem: [] },
    isFetching: isFetchingUsers,
  } = useQuery(["GET_USERS_IN_SYSTEM"], () => getUsersInSystem(), {});

  const {
    data = {
      staff: [],
    },
    isFetching,
  } = useQuery(["GET_GLOBAL_SETTINGS"], () => setUp(), {
    enabled: true,
    onError: () => {
      toast.error("Check connection and Try again");
      setShowGeneralSettingsModal(false);
    },
  });

  const handleSelectedJornalAccount = (account) => {
    formik.setFieldValue("drumAccountID", account?.AccountID);
    formik.setFieldValue("drumAccountDescription", account?.Description);
    setShowJournalPopover(false);
  };

  eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", (account) => {
    handleSelectedJornalAccount(account);
  });

  const handleSelectedJornalIncentiveAccount = (account) => {
    formik.setFieldValue("incentiveAccountID", account?.AccountID);
    formik.setFieldValue("incentiveAccountDescription", account?.Description);
    setShowJournalIncentiveAccountPopover(false);
  };

  eventBus.useCustomEventListener(
    "JOURNAL_INCENTIVE_ACCOUNT_CREATED",
    (account) => {
      handleSelectedJornalIncentiveAccount(account);
    }
  );

  const uploadSignatureApi = async ({ file, index }) => {
    const formData = new FormData();
    formData.append("signature", file, `invoice_signature`);
    formData.append("index", index);

    let response = await fetch(
      `${backendUrl}/api/company/upload-invoice-signature`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const uploadSignatureApiMutation = useMutation(
    (payload) => uploadSignatureApi(payload),
    {
      onSuccess: () => {
        toast.success("Uploaded");
        setkey(nanoid());
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const removeSignatureApi = async ({ index }) => {
    let response = await fetch(
      `${backendUrl}/api/company/remove-invoice-signature/${index}`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ index }),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const removeSignatureApiMutation = useMutation(
    (payload) => removeSignatureApi(payload),
    {
      onSuccess: () => {
        toast.success("Removed");
        setkey(nanoid());
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const uploadSignature = (file, index) => {
    console.log(index);
    uploadSignatureApiMutation.mutate({ file, index });
  };

  const removeSignature = async (index) => {
    if (
      await ConfirmDialog({
        title: "Remove Signature",
      })
    ) {
      removeSignatureApiMutation.mutate({ index });
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={showGeneralSettingsModal}
        onHide={() => setShowGeneralSettingsModal(false)}
        enforceFocus={false}
      >
        <Modal.Header closeButton className=" ">
          <Modal.Title className="h5">
            <GearIcon /> Settings
          </Modal.Title>
          <div className="   d-flex justify-content-end w-75">
            <Form.Group className=" border" style={{ maxWidth: "400px" }}>
              <InputGroup hasValidation>
                <Form.Control
                  name="account "
                  className="border-0"
                  placeholder="Search"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form
              noValidate
              onSubmit={formik.handleSubmit}
              autoComplete="off"
              style={
                isFetching || isFetchingUsers
                  ? { opacity: "0.5", pointerEvents: "none" }
                  : {}
              }
            >
              <DisplayChildElement
                label="Convert Quantity to Tons and pcs"
                searchText={debounceSearchText}
              >
                <h2 className="mb-3 h5">Personal</h2>
                <DisplayChildElement
                  label="Convert Quantity to Tons and pcs"
                  searchText={debounceSearchText}
                >
                  <Form.Group>
                    <Form.Check
                      inline
                      id="convertToTonsAndPcs"
                      label="Convert Quantity to Tons and pcs"
                      name="convertToTonsAndPcs"
                      type={"checkbox"}
                      checked={formik.values.convertToTonsAndPcs}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                </DisplayChildElement>
              </DisplayChildElement>

              {isAdmin ? (
                <DisplayChildElement
                  label="Enable Customers Credit Limit  Allow Credit Invoice for Walk-In Customer Purchase Price is required Proforma Invoice Quotation Store Name Inventory Service Checkout Button Text Hide Discount Column on Invoice Store Email Store Email Preset Item UnitCost/Price in update item Store Email Show Item Description on Invoice Preset Item UnitCost/Price in update item Hide Unit Price In Store Preset Unit Price in update item Unit Price Column name on Invoice Link Payment To Invoice Has Freight Vendor
                      Expense should be posted from account with balance Hide Unit Cost Invoice Item with not enough stock

                      Po Number Customer Can Edit Price in Cart Hide Unit Price Hide Unit Price

                      Hide Discount Show Item Description on Invoice Disable Payment at Checkout Preset Final Selling Price
                      Unit Price Column name on Invoice
                      Batch Name Column name
                      Can Add Batch Name Expense
                      Batch Name Column name Convertable UOM Name Show Batch Name on Invoice Link Payment To Invoice Edit Vendor Payment Delete Vendor Payment
                      Expense should be posted from account with balance Edit Journal Entry Can Back Date Fund Request Reimbursement Duration Enable Drum Account Cost Per Drum Enable General Discount TAX Percentage(%) Drum Account Exchange Rate Process Staff Salary in HR Item Expiry Filter Duration Incentive Account Proforma Invoice Title Purchase Price is required Preset Item UnitCost/Price in update item Add Customer Signature to Quote
Add Signature to Invoice
Enable Invoice Signatory Analyze Data for planning from: Item Expiry Filter Duration Expense
Edit Vendor Payment
Delete Vendor Payment
Edit Journal Entry
Show Processing Type Add Driver Info Before Supply Select Batch when Issuing Item
Show Invoice Cat Balance on Invoice
Farm
Inventory Selector
SingleMultiple
Inventory Management Supply Now
Lock Items In Supply Center
Lock Approved Items in Proforma
Create Invoice after Restock
Select Batch During Invoicing
Hide Unit Cost
Hide Unit Price
Hide Discount
Preset Final Selling Price
Lock Final Selling Price
Payment method Purchase Price is required
Preset Item UnitCost/Price in update item
Preset Unit Price in update item
Has Freight Vendor Item Type"
                  searchText={debounceSearchText}
                >
                  <div className="row border-top my-4 pt-4">
                    <h2 className="mb-3 h5">All Users</h2>
                    <div className="col-md-6">
                      <DisplayChildElement
                        label="Enable Customers Credit Limit"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="enableCreditLimit"
                            label="Enable Customers Credit Limit"
                            name="enableCreditLimit"
                            type={"checkbox"}
                            checked={formik.values.enableCreditLimit}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Allow Credit Invoice for Walk-In Customer"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="enableCreditPaymentForWalkIn"
                            label="Allow Credit Invoice for Walk-In Customer"
                            name="enableCreditPaymentForWalkIn"
                            type={"checkbox"}
                            checked={formik.values.enableCreditPaymentForWalkIn}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      {/*  <DisplayChildElement
                        label="Proforma Invoice Quotation"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Invoice workflow</Form.Label>

                          <div>
                            <Form.Check
                              inline
                              label="Proforma Invoice"
                              value="Proforma Invoice"
                              name="pendingInvoiceType"
                              type={"radio"}
                              id="Proforma Invoice"
                              checked={
                                formik.values.pendingInvoiceType ===
                                "Proforma Invoice"
                              }
                              onChange={formik.handleChange}
                            />
                            <Form.Check
                              inline
                              label="Quotation"
                              value="Quotation"
                              name="pendingInvoiceType"
                              type={"radio"}
                              id="Quotation"
                              checked={
                                formik.values.pendingInvoiceType === "Quotation"
                              }
                              onChange={formik.handleChange}
                            />
                          </div>
                        </Form.Group>
                      </DisplayChildElement> */}

                      {/*   <DisplayChildElement
                        label="Item Type"
                        searchText={debounceSearchText}
                      > */}
                      <Form.Group className="mb-3">
                        <Form.Label>Item Type</Form.Label>

                        <div>
                          <Form.Check
                            inline
                            label="Inventory"
                            value="Inventory"
                            name="Item_Type"
                            type={"radio"}
                            id="Inventory"
                            checked={formik.values.Item_Type === "Inventory"}
                            onChange={formik.handleChange}
                          />
                          <Form.Check
                            inline
                            label="Service"
                            value="Service"
                            name="Item_Type"
                            type={"radio"}
                            id="Service"
                            checked={formik.values.Item_Type === "Service"}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </Form.Group>
                      {/*   </DisplayChildElement> */}

                      {/* <Form.Group className="mb-3">
                  <Form.Label className="mb-1 text-nowrap">Currency</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    isSearchable={false}
                     value={didYouKnowOptions.find(
                      (el) => el.value === formik.values.How_Did_Know
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("How_Did_Know", value)
                    }
                    options={didYouKnowOptions}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group> */}

                      <DisplayChildElement
                        label="Hide Discount Column on Invoice"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="hideDiscountColumn"
                            label="Hide Discount Column on Invoice"
                            name="hideDiscountColumn"
                            type={"checkbox"}
                            checked={formik.values.hideDiscountColumn}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      {/* <DisplayChildElement
                        label="Add Signature to Invoice"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="addSignatureOnInvoice"
                            label="Add Signature to Invoice"
                            name="addSignatureOnInvoice"
                            type={"checkbox"}
                            checked={formik.values.addSignatureOnInvoice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement> */}

                      <DisplayChildElement
                        label="Show Item Description on Invoice"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="addItemDescriptionOnInvoice"
                            label="Show Item Description on Invoice"
                            name="addItemDescriptionOnInvoice"
                            type={"checkbox"}
                            checked={formik.values.addItemDescriptionOnInvoice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Unit Price Column name on Invoice"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Unit Price Column name on Invoice
                          </Form.Label>
                          <Form.Control
                            id="unitPriceTextOnInvoice"
                            name="unitPriceTextOnInvoice"
                            value={formik.values.unitPriceTextOnInvoice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Link Payment To Invoice"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="linkPaymentToInvoice"
                            label="Link Payment To Invoice"
                            name="linkPaymentToInvoice"
                            type={"checkbox"}
                            checked={formik.values.linkPaymentToInvoice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Expense should be posted from account with balance"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="balanceInExpenseFromAccount"
                            label="Expense should be posted from account with balance"
                            name="balanceInExpenseFromAccount"
                            type={"checkbox"}
                            checked={formik.values.balanceInExpenseFromAccount}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Invoice Item with not enough stock"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="invoiceNoStockItem"
                            label="Invoice Item with not enough stock"
                            name="invoiceNoStockItem"
                            type={"checkbox"}
                            checked={formik.values.invoiceNoStockItem}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Po Number"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="poNumber"
                            label="Po Number"
                            name="poNumber"
                            type={"checkbox"}
                            checked={formik.values.poNumber}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      {/*    <DisplayChildElement
                        label="Can Back Date"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="canBackDate"
                            label="Can Back Date"
                            name="canBackDate"
                            type={"checkbox"}
                            checked={formik.values.canBackDate}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement> */}

                      {/* <DisplayChildElement
                        label="Show Item Description on Invoice"
                        searchText={debounceSearchText}
                      >
                        <hr />

                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="addItemDescriptionOnInvoice"
                            label="Show Item Description on Invoice"
                            name="addItemDescriptionOnInvoice"
                            type={"checkbox"}
                            checked={formik.values.addItemDescriptionOnInvoice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement> */}

                      <DisplayChildElement
                        label="Unit Price Column name on Invoice"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Unit Price Column name on Invoice
                          </Form.Label>
                          <Form.Control
                            id="unitPriceTextOnInvoice"
                            name="unitPriceTextOnInvoice"
                            value={formik.values.unitPriceTextOnInvoice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Can Add Batch Name"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="canAddBatchName"
                            label="Can Add Batch Name"
                            name="canAddBatchName"
                            type={"checkbox"}
                            checked={formik.values.canAddBatchName}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Batch Name Column name"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Batch Name Column name</Form.Label>
                          <Form.Control
                            id="Batch_Name"
                            name="Batch_Name"
                            value={formik.values.Batch_Name}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Show Batch Name on Invoice"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="showBatchNameOnInvoice"
                            label="Show Batch Name on Invoice"
                            name="showBatchNameOnInvoice"
                            type={"checkbox"}
                            checked={formik.values.showBatchNameOnInvoice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      {/*   <DisplayChildElement
                        label="Link Payment To Invoice"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="linkPaymentToInvoice"
                            label="Link Payment To Invoice"
                            name="linkPaymentToInvoice"
                            type={"checkbox"}
                            checked={formik.values.linkPaymentToInvoice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement> */}

                      <DisplayChildElement
                        label="Can Back Date"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="canBackDate"
                            label="Can Back Date"
                            name="canBackDate"
                            type={"checkbox"}
                            checked={formik.values.canBackDate}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          Maximum Back Date Duration
                        </Form.Label>

                        <Row>
                          <Col>
                            <Select
                              classNamePrefix={"form-select"}
                              placeholder={"Select"}
                              value={backDateDurationOptions.find(
                                (el) =>
                                  el.value ===
                                  formik.values.maxBackDateDurationValue
                              )}
                              options={backDateDurationOptions}
                              onChange={({ value }) =>
                                formik.setFieldValue(
                                  "maxBackDateDurationValue",
                                  value
                                )
                              }
                            />
                          </Col>
                          <Col >
                            {!['Start of Year', "Custom Date"].includes(formik.values.maxBackDateDurationValue) && <NumberCustomInput
                              placeholder="0"
                              name="maxBackDateDurationNumber"
                              value={formik.values.maxBackDateDurationNumber}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value, true);
                              }}
                            />}

                            {formik.values.maxBackDateDurationValue === 'Custom Date' &&
                              <DateTime
                                timeFormat={false}
                                closeOnSelect={true}
                                closeOnClickOutside={true}
                                dateFormat="MMM DD, YYYY"
                                inputProps={{
                                  className: `date-input form-control`,
                                  placeholder: "Select date",
                                  readOnly: true,
                                }}
                                value={formik.values.maxBackDateCustom}
                                onChange={(date) => {
                                  formik.setFieldValue("maxBackDateCustom", date, true);
                                }}
                              />}
                          </Col>
                        </Row>
                      </Form.Group>
                      <hr />
                      <DisplayChildElement
                        label="Drums Account Enable Drum Account Cost Per Drum Drum Account"
                        searchText={debounceSearchText}
                      >
                        <h2 className="mb-3 h6">Drums Account</h2>
                        <DisplayChildElement
                          label="Enable Drum Account"
                          searchText={debounceSearchText}
                        >
                          <Form.Group className="mb-3">
                            <Form.Check
                              inline
                              id="enableDrumAccount"
                              label="Enable Drum Account"
                              name="enableDrumAccount"
                              type={"checkbox"}
                              checked={formik.values.enableDrumAccount}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                        </DisplayChildElement>

                        <DisplayChildElement
                          label="Cost Per Drum"
                          searchText={debounceSearchText}
                        >
                          <Form.Group className="mb-3">
                            <Form.Label>Cost Per Drum</Form.Label>
                            <CurrencyCustomInput
                              id="costPerDrum"
                              name="costPerDrum"
                              value={formik.values.costPerDrum}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value);
                              }}
                            />
                          </Form.Group>
                        </DisplayChildElement>

                        <DisplayChildElement
                          label="Drum Account"
                          searchText={debounceSearchText}
                        >
                          <Form.Group className="mb-3">
                            <Form.Label>Drum Account</Form.Label>
                            <InputGroup hasValidation>
                              <Form.Control
                                name="account"
                                placeholder="Select Drum Account"
                                value={`${formik.values?.drumAccountID ||
                                  "..."} / ${formik.values
                                    ?.drumAccountDescription || "..."}`}
                                onChange={() => { }}
                                readOnly
                              />

                              <Popover
                                isOpen={showJournalPopover}
                                onClickOutside={() =>
                                  setShowJournalPopover(false)
                                }
                                content={() => (
                                  <ExpensesSelector
                                    handleSelectedExpense={
                                      handleSelectedJornalAccount
                                    }
                                    usage={"chart of accounts"}
                                    onHide={() => setShowJournalPopover(false)}
                                  />
                                )}
                                position="bottom"
                              >
                                <InputGroup.Text
                                  onClick={() => setShowJournalPopover(true)}
                                >
                                  <MagnifyIcon />
                                </InputGroup.Text>
                              </Popover>
                            </InputGroup>
                          </Form.Group>
                        </DisplayChildElement>

                        <hr />
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Exchange Rate"
                        searchText={debounceSearchText}
                      >
                        <h2 className="mb-3 h6">Exchange Rate</h2>
                        <Form.Group className="mb-3">
                          <Form.Label>Select Prevailing Currency</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            placeholder="Select Currency"
                            options={currenciesOptionsAll}
                            value={currenciesOptionsAll.find(
                              (el) =>
                                el.value === formik.values.prevailingCurrency
                            )}
                            onChange={({ value }) => {
                              formik.setFieldValue("prevailingCurrency", value);
                            }}
                            isSearchable={true}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>
                            1 USD → {formik.values.prevailingCurrency}:
                          </Form.Label>
                          <CurrencyCustomInput
                            currencySymbol={formik.values.prevailingCurrency}
                            id="dollarInBaseCurrency"
                            name="dollarInBaseCurrency"
                            value={formik.values.dollarInBaseCurrency}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                          />
                        </Form.Group>

                        {/* <FieldArray
                          name="deploymentCurrencies"
                          render={(arrayHelpers) => (
                            <>
                              {formik.values.deploymentCurrencies && formik.values.deploymentCurrencies?.map(
                                (el, index) => (
                                  <div className="row mb-2 align-items-center">
                                    <span className="col-1 fw-bold">1</span>

                                    <Form.Group className="col d-flex align-items-center p-1">
                                      <Select
                                        classNamePrefix="form-select"
                                        placeholder="Select Currency"
                                        isSearchable={true}
                                        options={currenciesOptionsAll}
                                        value={currenciesOptionsAll.find(
                                          (el) =>
                                            el.value ===
                                            formik.values.deploymentCurrencies[
                                              index
                                            ].value
                                        )}
                                        onChange={(selected) => {
                                          for (let property in selected) {

                                            formik.setFieldValue(
                                              `deploymentCurrencies[${index}].${property}`,
                                              selected[property]
                                            );
                                          }
                                        }}
                                      />
                                    </Form.Group>

                                    <span className="col-1 fw-bold"> → </span>

                                    <Form.Group className="col-4">
                                      <CurrencyCustomInput
                                        currencySymbol={
                                          formik.values.prevailingCurrency
                                        }
                                        name="conversionAmount"
                                        value={
                                          formik.values.deploymentCurrencies[
                                            index
                                          ].conversionAmount
                                        }
                                        onValueChange={(value, name) => {
                                          formik.setFieldValue(
                                            `deploymentCurrencies[${index}].conversionAmount`,
                                            value
                                          );
                                        }}
                                        placeholder="0.00"
                                        className={"px-1"}
                                      />
                                    </Form.Group>

                                    <Button
                                      className="col-1"
                                      title="Remove"
                                      variant=""
                                      type="button"
                                      size="xs"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      ✖
                                    </Button>
                                  </div>
                                )
                              )}

                           <div className="d-flex justify-content-start px-4">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary text-nowrap"
                                  onClick={() => arrayHelpers.push({})}
                                >
                                  + Add
                                </button>
                              </div>
                      </>
                          )}
                        /> */}
                        <hr />
                      </DisplayChildElement>
                    </div>

                    <div className="col-md-6">
                      {" "}
                      <DisplayChildElement
                        label=" Create/Update Item (Non Admin user) Purchase Price is required Store Name Checkout Button Text Store Email Preset Item UnitCost/Price in update item Hide Unit Price In Store Preset Unit Price in update item Has Freight Vendor"
                        searchText={debounceSearchText}
                      >
                        <h2 className="mb-3 h6">
                          Create/Update Item (Non Admin user){" "}
                        </h2>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Purchase Price is required"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="isPurchasePriceRequired"
                            label="Purchase Price is required"
                            name="isPurchasePriceRequired"
                            type={"checkbox"}
                            checked={formik.values.isPurchasePriceRequired}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Store Name"
                        searchText={debounceSearchText}
                      >
                        <hr />
                        <Form.Group className="mb-3">
                          <Form.Label>Store Name</Form.Label>
                          <Form.Control
                            id="storeName"
                            name="storeName"
                            value={formik.values.storeName}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Checkout Button Text"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Checkout Button Text</Form.Label>
                          <Form.Control
                            id="checkoutButtonText"
                            name="checkoutButtonText"
                            value={formik.values.checkoutButtonText}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Store Email"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Store Email</Form.Label>
                          <Form.Control
                            id="storeEmail"
                            name="storeEmail"
                            type="email"
                            value={formik.values.storeEmail}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Preset Item UnitCost/Price in update item"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="presetItemValues"
                            label="Preset Item UnitCost/Price in update item"
                            name="presetItemValues"
                            type={"checkbox"}
                            checked={formik.values.presetItemValues}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Hide Unit Price In Store"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            id="hideUnitPriceInStore"
                            label="Hide Unit Price In Store"
                            name="hideUnitPriceInStore"
                            type={"checkbox"}
                            checked={formik.values.hideUnitPriceInStore}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <Form.Group className="mb-3">
                        <Form.Check
                          id="minimumStockByReorderLevel"
                          label="Mininum Stock in Store By Re-order Level"
                          name="minimumStockByReorderLevel"
                          type={"checkbox"}
                          checked={formik.values.minimumStockByReorderLevel}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                      <DisplayChildElement
                        label="Preset Unit Price in update item"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="presetUnitPrice"
                            label="Preset Unit Price in update item"
                            name="presetUnitPrice"
                            type={"checkbox"}
                            checked={formik.values.presetUnitPrice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>{" "}
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Has Freight Vendor"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="hasfreightVendor"
                            label="Has Freight Vendor"
                            name="hasfreightVendor"
                            type={"checkbox"}
                            checked={formik.values.hasfreightVendor}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="  Hide Unit Cost Customer Can Edit Price in Cart Hide Unit Price Enable Customer Verification Hide Discount Disable Payment at Checkout Preset Final Selling Price"
                        searchText={debounceSearchText}
                      >
                        <hr />
                        <h2 className="mb-3 h6">
                          Post Invoice (Non Admin user)
                        </h2>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Hide Unit Cost"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="hideUnitCost"
                            label="Hide Unit Cost"
                            name="hideUnitCost"
                            type={"checkbox"}
                            checked={formik.values.hideUnitCost}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Customer Can Edit Price in Cart"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            id="customerCanEditPriceInCart"
                            label="Customer Can Edit Price in Cart"
                            name="customerCanEditPriceInCart"
                            type={"checkbox"}
                            checked={formik.values.customerCanEditPriceInCart}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Hide Unit Price"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="hideUnitPrice"
                            label="Hide Unit Price"
                            name="hideUnitPrice"
                            type={"checkbox"}
                            checked={formik.values.hideUnitPrice}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Enable Customer Verification"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            id="customerVerifiction"
                            label="Enable Customer Verification"
                            name="customerVerification"
                            type={"checkbox"}
                            checked={formik.values.customerVerification}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Hide Discount"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="hideDiscount"
                            label="Hide Discount"
                            name="hideDiscount"
                            type={"checkbox"}
                            checked={formik.values.hideDiscount}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Disable Payment at Checkout"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            id="disablePaymentAtCheckout"
                            label="Disable Payment at Checkout"
                            name="disablePaymentAtCheckout"
                            type={"checkbox"}
                            checked={formik.values.disablePaymentAtCheckout}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Preset Final Selling Price"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="presetFinalSellingPrice"
                            label="Preset Final Selling Price"
                            name="presetFinalSellingPrice"
                            type={"checkbox"}
                            checked={formik.values.presetFinalSellingPrice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Expense Convertable UOM Name Edit Vendor Payment Delete Vendor Payment Edit Journal Entry Fund Request Reimbursement Duration Enable General Discount  General Discount Type General Discount (%)    General Discount Amount TAX  Percentage(%)"
                        searchText={debounceSearchText}
                      >
                        <hr />
                        <h2 className="mb-3 h6">Requires Admin Approval</h2>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Convertable UOM Name"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Convertable UOM Name</Form.Label>
                          <Form.Control
                            id="convertableUOM"
                            name="convertableUOM"
                            value={formik.values.convertableUOM}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Edit Vendor Payment"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="editVendorPaymentRequiresAdminApproval"
                            label="Edit Vendor Payment"
                            name="editVendorPaymentRequiresAdminApproval"
                            type={"checkbox"}
                            checked={
                              formik.values
                                .editVendorPaymentRequiresAdminApproval
                            }
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Delete Vendor Payment"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="deleteVendorPaymentRequiresAdminApproval"
                            label="Delete Vendor Payment"
                            name="deleteVendorPaymentRequiresAdminApproval"
                            type={"checkbox"}
                            checked={
                              formik.values
                                .deleteVendorPaymentRequiresAdminApproval
                            }
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Edit Journal Entry"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="editJournalRequiresAdminApproval"
                            label="Edit Journal Entry"
                            name="editJournalRequiresAdminApproval"
                            type={"checkbox"}
                            checked={
                              formik.values.editJournalRequiresAdminApproval
                            }
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Fund Request Reimbursement Duration"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Fund Request Reimbursement Duration
                          </Form.Label>

                          <Row>
                            <Col>
                              <NumberCustomInput
                                placeholder="0"
                                name="retirementDurationNumber"
                                value={formik.values.retirementDurationNumber}
                                onValueChange={(value, name) => {
                                  formik.setFieldValue(name, value, true);
                                }}
                              // isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                              />
                            </Col>
                            <Col>
                              <Select
                                classNamePrefix={"form-select"}
                                placeholder={""}
                                value={durationOptions.find(
                                  (el) =>
                                    el.value ===
                                    formik.values.retirementDurationValue
                                )}
                                options={durationOptions}
                                onChange={({ value }) =>
                                  formik.setFieldValue(
                                    "retirementDurationValue",
                                    value
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Enable General Discount"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            label="Enable General Discount"
                            id="enableGeneralDiscount"
                            name="enableGeneralDiscount"
                            type={"checkbox"}
                            checked={formik.values.enableGeneralDiscount}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>

                        {formik.values.enableGeneralDiscount && (
                          <Row>
                            <Form.Group className="col-md-5 mb-3 pb-2">
                              <Form.Label className="mb-1">
                                General Discount Type
                              </Form.Label>
                              <Select
                                classNamePrefix={"form-select"}
                                options={amountTypes}
                                value={amountTypes.find(
                                  (el) =>
                                    el.value ===
                                    formik.values.generalDiscountType
                                )}
                                onChange={(selected) =>
                                  formik.setFieldValue(
                                    "generalDiscountType",
                                    selected.value
                                  )
                                }
                              />
                            </Form.Group>

                            {formik.values.generalDiscountType ===
                              "Percentage" ? (
                              <Form.Group className="col-md-6 mb-3 pb-2">
                                <Form.Label className="mb-1">
                                  General Discount (%)
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="generalDiscountPercentage"
                                  value={
                                    formik.values.generalDiscountPercentage
                                  }
                                  onChange={formik.handleChange}
                                />
                              </Form.Group>
                            ) : (
                              <Form.Group className="col-md-7 mb-3 pb-2">
                                <Form.Label className="mb-1">
                                  General Discount Amount
                                </Form.Label>
                                <CurrencyCustomInput
                                  name="generalDiscount"
                                  placeholder="0.00"
                                  value={formik.values.generalDiscount}
                                  onValueChange={(value, name) => {
                                    formik.setFieldValue(name, value);
                                  }}
                                />
                              </Form.Group>
                            )}
                          </Row>
                        )}
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="TAX  Percentage(%)"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label className="d-flex justify-content-between">
                            <span>TAX</span> <span> Percentage(%)</span>
                          </Form.Label>

                          <FieldArray
                            name="taxOptions"
                            render={(arrayHelpers) => (
                              <>
                                <div className="row">
                                  {formik.values.taxOptions.map((el, index) => (
                                    <div className="d-flex gap-3 mb-2">
                                      <Form.Control
                                        name={`taxOptions[${index}].name`}
                                        placeholder="Name"
                                        value={
                                          formik.values.taxOptions[index].name
                                        }
                                        onChange={formik.handleChange}
                                      />
                                      <Form.Control
                                        type="number"
                                        name={`taxOptions[${index}].percentage`}
                                        placeholder="Percentage"
                                        value={
                                          formik.values.taxOptions[index]
                                            .percentage
                                        }
                                        onChange={formik.handleChange}
                                      />
                                      {/*  <Button
                                        title="Remove"
                                        variant=""
                                        type="button"
                                        size="xs"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        ✖
                                      </Button> */}
                                    </div>
                                  ))}
                                </div>
                                <div className="d-flex justify-content-start px-4">
                                  {/*   <button
                                    type="button"
                                    className="btn btn-sm btn-primary text-nowrap"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        department: "",
                                        jobGrade: "",
                                        staff: "",
                                      })
                                    }
                                  >
                                    + Add
                                  </button> */}
                                </div>
                              </>
                            )}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                    </div>
                    {/*  <Form.Group className="mb-3">
                    <Form.Label>Mandatory Signatory (Fund Request):</Form.Label>

                    <Select
                      classNamePrefix={"form-select"}
                      options={allUsersInSystem}
                      value={allUsersInSystem.find(
                        (el) =>
                          el.value === formik.values.requiredSignatoryStaff_ID
                      )}
                      onChange={(selected) =>
                        formik.setFieldValue(
                          "requiredSignatoryStaff_ID",
                          selected.value
                        )
                      }
                    />
                  </Form.Group> */}
                    <div className="col-md-6">
                      <DisplayChildElement
                        label="Process Staff Salary in HR"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="processStaffSalaryInHr"
                            label="Process Staff Salary in HR"
                            name="processStaffSalaryInHr"
                            type={"checkbox"}
                            checked={formik.values.processStaffSalaryInHr}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Item Expiry Filter Duration"
                        searchText={debounceSearchText}
                      >
                        <div>
                          <Form.Group className="mb-3 pb-2">
                            <Form.Label>Item Expiry Filter Duration</Form.Label>

                            <Row>
                              <Col>
                                <NumberCustomInput
                                  placeholder="0"
                                  name="expiryFilterDurationNumber"
                                  value={
                                    formik.values.expiryFilterDurationNumber
                                  }
                                  onValueChange={(value, name) => {
                                    formik.setFieldValue(name, value, true);
                                  }}
                                // isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                                />
                              </Col>
                              <Col>
                                <Select
                                  classNamePrefix={"form-select"}
                                  placeholder={""}
                                  value={durationOptions.find(
                                    (el) =>
                                      el.value ===
                                      formik.values.expiryFilterDurationValue
                                  )}
                                  options={durationOptions}
                                  onChange={({ value }) =>
                                    formik.setFieldValue(
                                      "expiryFilterDurationValue",
                                      value
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </div>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Incentive Account"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Incentive Account</Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              name="account"
                              placeholder="Select Incentive Account"
                              value={`${formik.values?.incentiveAccountID ||
                                "..."} / ${formik.values
                                  ?.incentiveAccountDescription || "..."}`}
                              onChange={() => { }}
                              readOnly
                            />

                            <Popover
                              isOpen={showJournalIncentiveAccountPopover}
                              onClickOutside={() =>
                                setShowJournalIncentiveAccountPopover(false)
                              }
                              content={() => (
                                <ExpensesSelector
                                  handleSelectedExpense={
                                    handleSelectedJornalIncentiveAccount
                                  }
                                  usage={"chart of accounts"}
                                  onHide={() =>
                                    setShowJournalIncentiveAccountPopover(false)
                                  }
                                  dispatchKeyForCreatedAccount={
                                    "JOURNAL_INCENTIVE_ACCOUNT_CREATED"
                                  }
                                />
                              )}
                              position="bottom"
                            >
                              <InputGroup.Text
                                onClick={() =>
                                  setShowJournalIncentiveAccountPopover(true)
                                }
                              >
                                <MagnifyIcon />
                              </InputGroup.Text>
                            </Popover>
                          </InputGroup>
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Proforma Invoice Title"
                        searchText={debounceSearchText}
                      >
                        <div>
                          <hr />
                        </div>
                        <Form.Group className="mb-3">
                          <Form.Label>Proforma Invoice Title</Form.Label>
                          <Form.Control
                            id="proformaInvoiceTitle"
                            name="proformaInvoiceTitle"
                            as={"textarea"}
                            placeholder="Enter Proforma Invoice Title"
                            value={formik.values.proformaInvoiceTitle}
                            onChange={formik.handleChange}
                            rows={3}
                          />{" "}
                        </Form.Group>
                      </DisplayChildElement>
                    </div>

                    <div className="col-md-6">
                      <DisplayChildElement
                        label="Hide Unit Cost Hide Unit Price Hide Discount Preset Final Selling Price Lock Final Selling Price Payment method Supply Now Lock Items In Supply Center Lock Approved Items in Proforma Create Invoice after Restock Select Batch During Invoicing Select Batch when Issuing Item Show Invoice Cat Balance on Invoice Inventory Selector Inventory Selector Inventory Management Add Driver Info Before Supply"
                        searchText={debounceSearchText}
                      >
                        <hr />
                        <h2 className="mb-3 h6">
                          Post Invoice (Non Admin user)
                        </h2>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Hide Unit Cost"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="hideUnitCost"
                            label="Hide Unit Cost"
                            name="hideUnitCost"
                            type={"checkbox"}
                            checked={formik.values.hideUnitCost}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Hide Unit Price"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="hideUnitPrice"
                            label="Hide Unit Price"
                            name="hideUnitPrice"
                            type={"checkbox"}
                            checked={formik.values.hideUnitPrice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Hide Discount"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="hideDiscount"
                            label="Hide Discount"
                            name="hideDiscount"
                            type={"checkbox"}
                            checked={formik.values.hideDiscount}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Preset Final Selling Price"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="presetFinalSellingPrice"
                            label="Preset Final Selling Price"
                            name="presetFinalSellingPrice"
                            type={"checkbox"}
                            checked={formik.values.presetFinalSellingPrice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Lock Final Selling Price"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="presetFinalSellingPrice"
                            label="Lock Final Selling Price"
                            name="lockFinalSellingPrice"
                            type={"checkbox"}
                            checked={formik.values.lockFinalSellingPrice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Payment method"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Payment method</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            menuPlacement="top"
                            placeholder="Choose method"
                            isSearchable={false}
                            value={paymentMethod.find(
                              (el) =>
                                el.value ===
                                formik.values.defaultInvoicePaymentType
                            )}
                            options={paymentMethod}
                            onChange={(selected) =>
                              formik.setFieldValue(
                                "defaultInvoicePaymentType",
                                selected?.value
                              )
                            }
                            getOptionLabel={(el) => (
                              <div className="label-with-icon d-flex gap-2 align-items-center">
                                <span>{el.icon}</span>{" "}
                                <span className="fw-5">{el.label}</span>
                              </div>
                            )}
                            isClearable
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Supply Now"
                        searchText={debounceSearchText}
                      >
                        <hr />
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="supplyNow"
                            label="Supply Now"
                            name="supplyNow"
                            type={"checkbox"}
                            checked={formik.values.supplyNow}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Invoice Due In (days)"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Invoice Due In (days)</Form.Label>
                          <NumberCustomInput
                            id="dueIn"
                            label="Invoice Due In (days)"
                            name="dueIn"
                            value={formik.values.dueIn}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            min={1}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Vendor Invoice Due In (days)"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Vendor Invoice Due In (days)</Form.Label>
                          <NumberCustomInput
                            id="vendorDueIn"
                            label="Vendor Invoice Due In (days)"
                            name="vendorDueIn"
                            value={formik.values.vendorDueIn}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            min={1}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Lock Items In Supply Center"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="lockItemsInSupplyCenter"
                            label="Lock Items In Supply Center"
                            name="lockItemsInSupplyCenter"
                            type={"checkbox"}
                            checked={formik.values.lockItemsInSupplyCenter}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Lock Approved Items in Proforma"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="lockApprovedItemsInProforma"
                            label="Lock Approved Items in Proforma"
                            name="lockApprovedItemsInProforma"
                            type={"checkbox"}
                            checked={formik.values.lockApprovedItemsInProforma}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Create Invoice after Restock"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="createInvoiceAfterRestock"
                            label="Create Invoice after Restock"
                            name="createInvoiceAfterRestock"
                            type={"checkbox"}
                            checked={formik.values.createInvoiceAfterRestock}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Service Invoice Requires Approval"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="serviceInvoiceRequiresApproval"
                            label="Service Invoice Requires Approval"
                            name="serviceInvoiceRequiresApproval"
                            type={"checkbox"}
                            checked={
                              formik.values.serviceInvoiceRequiresApproval
                            }
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Select Batch During Invoicing"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="selectBatchDuringInvoicing"
                            label="Select Batch During Invoicing"
                            name="selectBatchDuringInvoicing"
                            type={"checkbox"}
                            checked={formik.values.selectBatchDuringInvoicing}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Select Batch when Issuing Item"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="selectBatchWhenIssuingItem"
                            label="Select Batch when Issuing Item"
                            name="selectBatchWhenIssuingItem"
                            type={"checkbox"}
                            checked={formik.values.selectBatchWhenIssuingItem}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Show Invoice Cat Balance on Invoice"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="withInvoiceCatBalance"
                            label="Show Invoice Cat Balance on Invoice"
                            name="withInvoiceCatBalance"
                            type={"checkbox"}
                            checked={formik.values.withInvoiceCatBalance}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Farm"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="isFarm"
                            label="Farm"
                            name="isFarm"
                            type={"checkbox"}
                            checked={formik.values.isFarm}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Inventory Selector"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Inventory Selector</Form.Label>

                          <div>
                            <Form.Check
                              inline
                              label="Single"
                              value="Single"
                              name="inventorySelector"
                              type={"radio"}
                              id="Single"
                              checked={
                                formik.values.inventorySelector === "Single"
                              }
                              onChange={formik.handleChange}
                            />
                            <Form.Check
                              inline
                              label="Multiple List"
                              value="Multiple List"
                              name="inventorySelector"
                              type={"radio"}
                              id="Multiple List"
                              checked={
                                formik.values.inventorySelector ===
                                "Multiple List"
                              }
                              onChange={formik.handleChange}
                            />

                            <Form.Check
                              inline
                              label="Multiple Grid"
                              value="Multiple Grid"
                              name="inventorySelector"
                              type={"radio"}
                              id="Multiple Grid"
                              checked={
                                formik.values.inventorySelector ===
                                "Multiple Grid"
                              }
                              onChange={formik.handleChange}
                            />
                          </div>
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Inventory Selector"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Invoicing Type</Form.Label>

                          <div>
                            <Form.Check
                              inline
                              label="Standard"
                              value="Standard"
                              name="invoicingType"
                              type={"radio"}
                              id="Standard"
                              checked={
                                formik.values.invoicingType === "Standard"
                              }
                              onChange={formik.handleChange}
                            />
                            <Form.Check
                              inline
                              label="Point of Sale"
                              value="Point of Sale"
                              name="invoicingType"
                              type={"radio"}
                              id="Point of Sale"
                              checked={
                                formik.values.invoicingType === "Point of Sale"
                              }
                              onChange={formik.handleChange}
                            />
                          </div>
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Inventory Management"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label>Inventory Management</Form.Label>
                          <Select
                            classNamePrefix={"form-select"}
                            options={inventoryManagementOptions}
                            value={inventoryManagementOptions.find(
                              (el) =>
                                el.value === formik.values.inventoryManagement
                            )}
                            onChange={(selected) =>
                              formik.setFieldValue(
                                "inventoryManagement",
                                selected?.value
                              )
                            }
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Add Driver Info Before Supply"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="addDriverInfoBeforeSupply"
                            label="Add Driver Info Before Supply"
                            name="addDriverInfoBeforeSupply"
                            type={"checkbox"}
                            checked={formik.values.addDriverInfoBeforeSupply}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Expense Edit Vendor Payment Delete Vendor Payment Edit Journal  Show Processing Type Item Expiry Filter Duration "
                        searchText={debounceSearchText}
                      >
                        <h2 className="mb-3 h6">Requires Admin Approval</h2>
                        <hr />
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Expense"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="expenseRequiresAdminApproval"
                            label="Expense"
                            name="expenseRequiresAdminApproval"
                            type={"checkbox"}
                            checked={formik.values.expenseRequiresAdminApproval}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Edit Vendor Payment"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="editVendorPaymentRequiresAdminApproval"
                            label="Edit Vendor Payment"
                            name="editVendorPaymentRequiresAdminApproval"
                            type={"checkbox"}
                            checked={
                              formik.values
                                .editVendorPaymentRequiresAdminApproval
                            }
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Delete Vendor Payment"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="deleteVendorPaymentRequiresAdminApproval"
                            label="Delete Vendor Payment"
                            name="deleteVendorPaymentRequiresAdminApproval"
                            type={"checkbox"}
                            checked={
                              formik.values
                                .deleteVendorPaymentRequiresAdminApproval
                            }
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      <DisplayChildElement
                        label="Edit Journal Entry"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="editJournalRequiresAdminApproval"
                            label="Edit Journal Entry"
                            name="editJournalRequiresAdminApproval"
                            type={"checkbox"}
                            checked={
                              formik.values.editJournalRequiresAdminApproval
                            }
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Show Processing Type"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="itemStatusSettings"
                            label="Show Processing Type"
                            name="itemStatusSettings"
                            type={"checkbox"}
                            checked={formik.values.itemStatusSettings}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Restock Requires Approval"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="restockRequiresApproval"
                            label="Restock Requires Approval"
                            name="restockRequiresApproval"
                            type={"checkbox"}
                            checked={formik.values.restockRequiresApproval}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Restock Requires PO Number"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="restockRequiresPONumber"
                            label="Restock Requires PO Number"
                            name="restockRequiresPONumber"
                            type={"checkbox"}
                            checked={formik.values.restockRequiresPONumber}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <Form.Group className="mb-3">
                        <Form.Label>Record Cost of Sales During</Form.Label>
                        <div>
                          <Form.Check
                            inline
                            label="Restocking (Acural)"
                            value="Restocking"
                            name="recordCostofGoodsDuring"
                            type={"radio"}
                            id="Restocking"
                            checked={
                              formik.values.recordCostofGoodsDuring ===
                              "Restocking"
                            }
                            onChange={formik.handleChange}
                          />
                          <Form.Check
                            inline
                            label="Invoicing"
                            value="Invoicing"
                            name="recordCostofGoodsDuring"
                            type={"radio"}
                            id="Invoicing"
                            checked={
                              formik.values.recordCostofGoodsDuring ===
                              "Invoicing"
                            }
                            onChange={formik.handleChange}
                          />
                        </div>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Record Cost of Sales By</Form.Label>
                        <div>
                          <Form.Check
                            inline
                            label="Weighted Average"
                            value="weightedAverage"
                            name="recordCostOfSalesBy"
                            type={"radio"}
                            id="weightedAverage"
                            checked={
                              formik.values.recordCostOfSalesBy ===
                              "weightedAverage"
                            }
                            onChange={formik.handleChange}
                          />
                          <Form.Check
                            inline
                            label="Batch Used"
                            value="batchUsed"
                            name="recordCostOfSalesBy"
                            type={"radio"}
                            id="batchUsed"
                            checked={
                              formik.values.recordCostOfSalesBy === "batchUsed"
                            }
                            onChange={formik.handleChange}
                          />
                        </div>
                      </Form.Group>

                      {/* <DisplayChildElement
                        label="Item Expiry Filter Duration"
                        searchText={debounceSearchText}
                      >
                        <div>
                          <Form.Group className="mb-3 pb-2">
                            <Form.Label>Item Expiry Filter Duration</Form.Label>
                            <Row>
                              <Col>
                                <NumberCustomInput
                                  placeholder="0"
                                  name="expiryFilterDurationNumber"
                                  value={
                                    formik.values.expiryFilterDurationNumber
                                  }
                                  onValueChange={(value, name) => {
                                    formik.setFieldValue(name, value, true);
                                  }}
                                  // isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                                />
                              </Col>
                              <Col>
                                <Select
                                  classNamePrefix={"form-select"}
                                  placeholder={""}
                                  value={durationOptions.find(
                                    (el) =>
                                      el.value ===
                                      formik.values.expiryFilterDurationValue
                                  )}
                                  options={durationOptions}
                                  onChange={({ value }) =>
                                    formik.setFieldValue(
                                      "expiryFilterDurationValue",
                                      value
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </div>
                      </DisplayChildElement> */}

                      <DisplayChildElement
                        label="Analyze Data for planning from: Plan For Add Customer Signature to Quote Add Signature to Invoice Enable Invoice Signatory"
                        searchText={debounceSearchText}
                      >
                        <hr />
                        <h2 className="mb-3 h6">Procurement Planning</h2>
                      </DisplayChildElement>
                      <div>
                        <DisplayChildElement
                          label="Analyze Data for planning from: "
                          searchText={debounceSearchText}
                        >
                          <Form.Group className="mb-3 pb-2">
                            <Form.Label>
                              Analyze Data for planning from:{" "}
                            </Form.Label>
                            <Row>
                              <Col>
                                <NumberCustomInput
                                  placeholder="0"
                                  name="analysedataFrom"
                                  value={formik.values.analyseDataFrom}
                                  onValueChange={(value, name) => {
                                    formik.setFieldValue(name, value, true);
                                  }}
                                />
                              </Col>
                              <Col>
                                <p className="fw-bold mt-3">Months</p>
                              </Col>
                            </Row>
                          </Form.Group>
                        </DisplayChildElement>

                        <DisplayChildElement
                          label="Plan For"
                          searchText={debounceSearchText}
                        >
                          <Form.Group className="mb-3 pb-2">
                            <Form.Label>Plan For</Form.Label>
                            <Row>
                              <Col>
                                <NumberCustomInput
                                  placeholder="0"
                                  name="forcastDataFor"
                                  value={formik.values.forcastDataFor}
                                  onValueChange={(value, name) => {
                                    formik.setFieldValue(name, value, true);
                                  }}
                                />
                              </Col>
                              <Col>
                                <p className="fw-bold mt-3">Months</p>
                              </Col>
                            </Row>
                          </Form.Group>
                        </DisplayChildElement>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <DisplayChildElement
                        label="Add Customer Signature to Quote"
                        searchText={debounceSearchText}
                      >
                        <hr />
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="addCustomerSignatureOnInvoice"
                            label="Add Customer Signature to Quote"
                            name="addCustomerSignatureOnInvoice"
                            type={"checkbox"}
                            checked={
                              formik.values.addCustomerSignatureOnInvoice
                            }
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Add Signature to Invoice"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            id="addSignatureOnInvoice"
                            label="Add Signature to Invoice"
                            name="addSignatureOnInvoice"
                            type={"checkbox"}
                            checked={formik.values.addSignatureOnInvoice}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>

                      <DisplayChildElement
                        label="Enable Invoice Signatory"
                        searchText={debounceSearchText}
                      >
                        <Form.Group className="mb-3">
                          <Form.Check
                            id="enableInvoiceSignatory"
                            name="enableInvoiceSignatory"
                            label="Enable Invoice Signatory"
                            type={"checkbox"}
                            checked={formik.values.enableInvoiceSignatory}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      </DisplayChildElement>
                      {formik.values.enableInvoiceSignatory && (
                        <FieldArray
                          name="invoiceSignatories"
                          render={(arrayHelpers) => (
                            <>
                              <div className="row">
                                {formik.values.invoiceSignatories.map(
                                  (el, index) => (
                                    <div className="col-6 position-relative">
                                      <div className="d-flex justify-content-end">
                                        <Button
                                          title="Remove"
                                          variant="outline-danger"
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          ✖
                                        </Button>
                                      </div>

                                      <Form.Group className="mb-3">
                                        <Form.Label>
                                          Invoice Signatory Name
                                        </Form.Label>
                                        <Form.Control
                                          id="invoiceSignatoryStaffName"
                                          name={`invoiceSignatories[${index}]invoiceSignatoryStaffName`}
                                          placeholder="Enter Invoice Signatory Name"
                                          value={
                                            formik.values.invoiceSignatories[
                                              index
                                            ].invoiceSignatoryStaffName
                                          }
                                          onChange={formik.handleChange}
                                        />
                                      </Form.Group>

                                      <Form.Group className="mb-3">
                                        <Form.Label>
                                          {" "}
                                          Invoice Signatory Title
                                        </Form.Label>
                                        <Form.Control
                                          id="invoiceSignatoryStaffTitle"
                                          name={`invoiceSignatories[${index}]invoiceSignatoryStaffTitle`}
                                          placeholder="Enter Invoice Signatory Title"
                                          value={
                                            formik.values.invoiceSignatories[
                                              index
                                            ].invoiceSignatoryStaffTitle
                                          }
                                          onChange={formik.handleChange}
                                        />{" "}
                                      </Form.Group>

                                      <Form.Group className="mb-3">
                                        <Form.Label>
                                          Upload Invoice Signature
                                        </Form.Label>
                                        <br />
                                        <Form.Label className="btn btn-light-blue">
                                          <span>
                                            Select Image{" "}
                                            <i>
                                              {formik.values.invoiceSignatories[
                                                index
                                              ].isLoading
                                                ? "Uploading..."
                                                : ""}
                                            </i>
                                          </span>
                                          <Form.Control
                                            type="file"
                                            className="d-none"
                                            accept="image/*"
                                            multiple
                                            onChange={(e) =>
                                              uploadSignature(
                                                e.target.files[0],
                                                index
                                              )
                                            }
                                          />
                                        </Form.Label>
                                        <div>
                                          <img
                                            key={key}
                                            style={{
                                              width: "10rem",
                                            }}
                                            alt=""
                                            src={`${backendUrl}/api/company/invoice-signature/${index}?key=${key}`}
                                          />{" "}
                                        </div>{" "}
                                        <Button
                                          variant=""
                                          title="Remove Signature"
                                          style={{ left: 0 }}
                                          onClick={() => removeSignature(index)}
                                        >
                                          <DropdownCloseIcon /> Remove Image
                                        </Button>
                                      </Form.Group>
                                    </div>
                                  )
                                )}{" "}
                              </div>

                              <div className="d-flex justify-content-start px-4">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary text-nowrap"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      invoiceSignatoryStaffName: "",
                                      invoiceSignatoryStaffTitle: "",
                                      invoiceSignatoryStaffImage: "",
                                    })
                                  }
                                >
                                  + Add Invoice Signatory
                                </button>
                              </div>
                            </>
                          )}
                        />
                      )}
                    </div>
                  </div>
                </DisplayChildElement>
              ) : null}

              {isAdmin || isDocumentControl ? (
                <DisplayChildElement
                  label="Fund Request Number of Mandatory Signatories before Approve & Close add Material Request Allowance"
                  searchText={debounceSearchText}
                >
                  <MandatorySignatorySettings formik={formik} />
                </DisplayChildElement>
              ) : null}

              <div>
                <DisplayChildElement
                  label="Warehouse Recieve Assurance RM & FP Approval C of A Approval Default Requisition Sort Enable Selling Price in Requisition Invoice"
                  searchText={debounceSearchText}
                >
                  <hr />

                  <h2 className="mb-3 h5">Quality Assurance </h2>
                </DisplayChildElement>

                <div className="row">
                  <div className="col-6">
                    <DisplayChildElement
                      label="Warehouse Recieve Assurance"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Check
                          inline
                          id="WarehouseRecieveAssurance"
                          label="Warehouse Recieve Assurance"
                          name="WarehouseRecieveAssurance"
                          type={"checkbox"}
                          checked={formik.values.WarehouseRecieveAssurance}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    </DisplayChildElement>
                  </div>
                  <div className="col-6">
                    <DisplayChildElement
                      label="RM & FP Approval"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Check
                          inline
                          id="RM_FPApproval"
                          label="RM & FP Approval"
                          name="RM_FPApproval"
                          type={"checkbox"}
                          checked={formik.values.RM_FPApproval}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    </DisplayChildElement>

                    <DisplayChildElement
                      label="C of A Approval"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Check
                          inline
                          id="CofAApproval"
                          label="C of A Approval"
                          name="CofAApproval"
                          type={"checkbox"}
                          checked={formik.values.CofAApproval}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    </DisplayChildElement>
                    <DisplayChildElement
                      label="Default Requisition Sort"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="sortByArchieve">
                          Sort Requisition By
                        </Form.Label>
                        {/* <Form.Check
                          inline
                          id="sortByArchieve"
                          label="Default Requisition Sort"
                          name="sortByArchieve"
                          type={"checkbox"}
                          checked={formik.values.sortByArchieve}
                          onChange={formik.handleChange}
                        /> */}

                        <Form.Select
                          name="sortByArchieve"
                          label="Default Requisition Sort"
                          id="sortByArchieve"
                          value={formik.values.sortByArchieve}
                          className="py-2"
                          onChange={formik.handleChange}

                        // onChange={(e) => handleSearchQueryChange(e)}
                        >
                          {sortByArchive?.map((archive) => (
                            <option value={archive.value}>
                              {archive.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </DisplayChildElement>
                    <DisplayChildElement
                      label="Enable Selling Price in Requisition Invoice"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Check
                          inline
                          id="enableSellingPriceInRequisitionInvoice"
                          label="Enable Selling Price in Requisition Invoice"
                          name="enableSellingPriceInRequisitionInvoice"
                          type={"checkbox"}
                          checked={
                            formik.values.enableSellingPriceInRequisitionInvoice
                          }
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    </DisplayChildElement>
                  </div>
                </div>
              </div>
              <div>
                <DisplayChildElement
                  label="Pharmacy Setting"
                  searchText={debounceSearchText}
                >
                  <hr />
                  <h2 className="mb-3 h5">Pharmacy Settings </h2>
                </DisplayChildElement>

                <DisplayChildElement
                  label="Pharmacy Setting"
                  searchText={debounceSearchText}
                >
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="pharmacySetting"
                      label="Pharmacy Setting"
                      name="pharmacySetting"
                      type={"checkbox"}
                      checked={formik.values.pharmacySetting}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                </DisplayChildElement>
              </div>

              <div>
                <hr />

                <div className="row">
                  <div className="col-6">
                    <DisplayChildElement
                      label="Items Requires Serial Number"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Check
                          inline
                          id="itemsRequiresSerialNumber"
                          label="Items Requires Serial Number"
                          name="itemsRequiresSerialNumber"
                          type={"checkbox"}
                          checked={formik.values.itemsRequiresSerialNumber}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>{" "}
                    </DisplayChildElement>

                    <DisplayChildElement
                      label="Approved Inbound PO"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Check
                          inline
                          id="approvedImboundPo"
                          label="Approved Inbound PO"
                          name="approvedImboundPo"
                          type={"checkbox"}
                          checked={formik.values.approvedImboundPo}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    </DisplayChildElement>

                    <DisplayChildElement
                      label="Vendor Invoice Requires Approval"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Check
                          inline
                          id="vendorInvoiceRequiresApproval"
                          label="Vendor Invoice Requires Approval"
                          name="vendorInvoiceRequiresApproval"
                          type={"checkbox"}
                          checked={formik.values.vendorInvoiceRequiresApproval}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    </DisplayChildElement>

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="vendorVerificationRequiresApproval"
                        label="Vendor Verification Requires Approval"
                        name="vendorVerificationRequiresApproval"
                        type={"checkbox"}
                        checked={
                          formik.values.vendorVerificationRequiresApproval
                        }
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="vendorDeleteRequiresApproval"
                        label="Vendor Delete Requires Approval"
                        name="vendorDeleteRequiresApproval"
                        type={"checkbox"}
                        checked={formik.values.vendorDeleteRequiresApproval}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="excludeBranchesInSalesByInvoice"
                        label="Exclude Branches In Sales By Invoice"
                        name="excludeBranchesInSalesByInvoice"
                        type={"checkbox"}
                        checked={formik.values.excludeBranchesInSalesByInvoice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-6">
                    <DisplayChildElement
                      label="Sales Inventory Name"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>Sales Inventory Name</Form.Label>
                        <Form.Control
                          id="salesInventoryName"
                          name="salesInventoryName"
                          value={formik.values.salesInventoryName}
                          onChange={formik.handleChange}
                        />{" "}
                      </Form.Group>
                    </DisplayChildElement>

                    <DisplayChildElement
                      label="Procurement Name"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>Procurement Name</Form.Label>
                        <Form.Control
                          id="procurementName"
                          name="procurementName"
                          value={formik.values.procurementName}
                          onChange={formik.handleChange}
                        />{" "}
                      </Form.Group>
                    </DisplayChildElement>

                    <Form.Group className="mb-3">
                      <Form.Label>Quantity Label</Form.Label>
                      <Form.Control
                        id="quantityLabel"
                        name="quantityLabel"
                        value={formik.values.quantityLabel}
                        onChange={formik.handleChange}
                      />{" "}
                    </Form.Group>

                    <DisplayChildElement
                      label=" Item Search Criteria"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label> Item Search Criteria</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          placeholder="Search Criteria"
                          isSearchable={false}
                          options={itemSearchCriteriaOptions}
                          value={itemSearchCriteriaOptions.find(
                            (el) =>
                              el.value === formik.values.itemSearchCriteria
                          )}
                          onChange={({ value }) => {
                            formik.setFieldValue("itemSearchCriteria", value);
                          }}
                        />
                      </Form.Group>
                    </DisplayChildElement>
                    <DisplayChildElement
                      label="Number of Days to Refill"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label> Number of Days to Refill</Form.Label>
                        <Form.Control
                          name="numberOfDaysToRefill"
                          placeholder="Enter number of days to refill "
                          type="number"
                          onChange={formik.handleChange}
                          value={formik.values.numberOfDaysToRefill}
                        />
                      </Form.Group>
                    </DisplayChildElement>
                    <DisplayChildElement
                      label="Refill percentage (%)"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label> Refill percentage (%)</Form.Label>
                        <Form.Control
                          name="refillPercentage"
                          placeholder="Enter percentage"
                          type="number"
                          onChange={formik.handleChange}
                          value={formik.values.refillPercentage}
                        />
                      </Form.Group>
                    </DisplayChildElement>
                    <DisplayChildElement
                      label=" Number of Days Stock is Needed"
                      searchText={debounceSearchText}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label> Number of Days Stock is Needed</Form.Label>
                        <Form.Control
                          name="numberOfDaysStockIsNeeded"
                          placeholder="Enter number of days stock is needed "
                          type="number"
                          onChange={formik.handleChange}
                          value={formik.values.numberOfDaysStockIsNeeded}
                        />
                      </Form.Group>
                    </DisplayChildElement>
                  </div>
                </div>
              </div>
              <DisplayChildElement
                label="Use Standard Procurmement Plan"
                searchText={debounceSearchText}
              >
                <div>
                  <h2 className="mb-3 h5"> </h2>
                  <div className=" col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>Select Procurement Plan</Form.Label>
                      {/* <Form.Check
                        inline
                        id="standardProcurementPlan"
                        label="Use Standard Procurmement Plan"
                        name="standardProcurementPlan"
                        type={"checkbox"}
                        checked={formik.values.standardProcurementPlan}
                        onChange={formik.handleChange}
                      /> */}

                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select procurement plan type "
                        isSearchable={false}
                        options={procurementPlantTypeOptions}
                        value={procurementPlantTypeOptions.find(
                          (el) =>
                            el.value === formik.values.standardProcurementPlan
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue(
                            "standardProcurementPlan",
                            value
                          );
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
              </DisplayChildElement>
              <DisplayChildElement
                label=" Large Quick Link"
                searchText={debounceSearchText}
              >
                <div>
                  <hr />
                  <h2 className="mb-3 h5"> </h2>

                  <div>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="largeQuickLink"
                        label="Large Quick Link"
                        name="largeQuickLink"
                        type={"checkbox"}
                        checked={formik.values.largeQuickLink}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
              </DisplayChildElement>

              <DisplayChildElement
                label="AI Assistant"
                searchText={debounceSearchText}
              >
                <Form.Group className="mb-3">
                  <Form.Check
                    inline
                    id="aiAssistant"
                    label="AI Assistant"
                    name="aiAssistant"
                    type={"checkbox"}
                    checked={formik.values.aiAssistant}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </DisplayChildElement>

              <DisplayChildElement
                label="Recipient Email for Expired Items"
                searchText={debounceSearchText}
              >
                <Form.Group className="mb-3">
                  <Form.Label className="d-flex justify-content-between">
                    Recipient Email for Expired Items
                  </Form.Label>

                  <FieldArray
                    name="expiredItemsRecipientEmail"
                    render={(arrayHelpers) => (
                      <>
                        <div className="row">
                          {formik.values.expiredItemsRecipientEmail?.map(
                            (el, index) => (
                              <div className="col-md-6">
                                <div className="d-flex gap-3 mb-2 ">
                                  <Form.Control
                                    type="email"
                                    name={`expiredItemsRecipientEmail[${index}].email`}
                                    placeholder="Email address"
                                    value={
                                      formik.values.expiredItemsRecipientEmail[
                                        index
                                      ].email
                                    }
                                    onChange={formik.handleChange}
                                  />

                                  <Button
                                    title="Remove"
                                    variant=""
                                    type="button"
                                    size="xs"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    ✖
                                  </Button>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div className="d-flex justify-content-start px-4">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary text-nowrap"
                            onClick={() =>
                              arrayHelpers.push({
                                email: "",
                              })
                            }
                          >
                            + Add
                          </button>
                        </div>
                      </>
                    )}
                  />
                </Form.Group>
              </DisplayChildElement>

              <div className="row">
                <DisplayChildElement
                  label="Add Insurance Credit Lines"
                  searchText={debounceSearchText}
                >
                  <Form.Group className="mb-3">
                    <Form.Label className="d-flex justify-content-between">
                      Add Insurance Credit Lines
                    </Form.Label>

                    <FieldArray
                      name="insuranceCreditLines"
                      render={(arrayHelpers) => (
                        <>
                          <div className="row">
                            {formik.values.insuranceCreditLines.map(
                              (el, index) => (
                                <div className="col-md-6">
                                  <div className="d-flex gap-3 mb-2 ">
                                    <Form.Control
                                      type="text"
                                      name={`insuranceCreditLines[${index}].creditLine`}
                                      placeholder="Credit Line"
                                      value={
                                        formik.values.insuranceCreditLines[
                                          index
                                        ].creditLine
                                      }
                                      onChange={formik.handleChange}
                                    />

                                    <Button
                                      title="Remove"
                                      variant=""
                                      type="button"
                                      size="xs"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      ✖
                                    </Button>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                          <div className="d-flex justify-content-start px-4">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary text-nowrap"
                              onClick={() =>
                                arrayHelpers.push({
                                  creditLine: "",
                                })
                              }
                            >
                              + Add
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </Form.Group>
                </DisplayChildElement>
              </div>
              {/*  */}

              <DisplayChildElement
                label="TAX  Percentage(%)"
                searchText={debounceSearchText}
              >
                <Form.Group className="mb-3">
                  <Form.Label className="d-flex justify-content-between">
                    Waybill Signatories
                  </Form.Label>

                  <FieldArray
                    name="waybillSignatories"
                    render={(arrayHelpers) => (
                      <>
                        <div className="row">
                          {formik.values.waybillSignatories?.map(
                            (el, index) => (
                              <div className="col-md-6">
                                <div className="d-flex gap-3 mb-2 ">
                                  <Form.Control
                                    type="name"
                                    name={`waybillSignatories[${index}].name`}
                                    placeholder="Signature Name"
                                    value={
                                      formik.values.waybillSignatories[index]
                                        .name
                                    }
                                    onChange={formik.handleChange}
                                  />

                                  <Button
                                    title="Remove"
                                    variant=""
                                    type="button"
                                    size="xs"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    ✖
                                  </Button>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div className="d-flex justify-content-start px-4">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary text-nowrap"
                            onClick={() =>
                              arrayHelpers.push({
                                name: "",
                              })
                            }
                          >
                            + Add
                          </button>
                        </div>
                      </>
                    )}
                  />
                </Form.Group>
              </DisplayChildElement>
              <DisplayChildElement
                label="Delivery Information on Invoice"
                searchText={debounceSearchText}
              >
                <Form.Group className="mb-3">
                  <Form.Check
                    inline
                    id="deliveryInformationOnInvoice"
                    label="Delivery Information on Invoice"
                    name="deliveryInformationOnInvoice"
                    type={"checkbox"}
                    checked={formik.values.deliveryInformationOnInvoice}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </DisplayChildElement>
            </Form>
          </FormikProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => formik.submitForm()}
            variant="primary"
            disabled={addSettingsMutation.isLoading}
            className="px-4"
          >
            {addSettingsMutation.isLoading ? "Please wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal >
      <ModalLoader
        show={
          removeSignatureApiMutation.isLoading ||
          uploadSignatureApiMutation.isLoading
        }
      />
    </>
  );
}
