import React, { useState } from 'react';
import Pin from 'mdi-react/PinIcon';
import PinOff from 'mdi-react/PinOffIcon';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ExpenseSettings = ({ initialPinned = false, onPinChange = () => { } }) => {
  const [isPinned, setIsPinned] = useState(initialPinned);

  const handleToggle = () => {
    const newPinnedState = !isPinned;
    setIsPinned(newPinnedState);
    onPinChange(newPinnedState);
  };

  return (
    <Button
      //  onClick={handleToggle}
      onClick={() => toast.info('Coming soon')}
      className="border"
      aria-label={isPinned ? "Unpin item" : "Pin item"}
      title={isPinned ? "Unpin" : "Pin"}
      variant=''
    >
      {isPinned ? (
        <Pin />
      ) : (
        <PinOff />
      )}
    </Button>
  );
};

export default ExpenseSettings;
