import { useEffect, useMemo, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";

import { fetchActionsUtil } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import { useResolveRootRoles } from "../../utils/hooks";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";

export function WarehouseInventoryNav(props) {
  const { backendUrl } = useAuth();
  const { getRootRoles } = useResolveRootRoles();
  const fileHolder = useRef(null);
  const [isShowLeftSroll, setShowLeftScroll] = useState(false);
  const [isShowRightSroll, setShowRightScroll] = useState(false);

  const showLeftSroll = () => {
    setShowLeftScroll(fileHolder?.current && fileHolder.current.scrollLeft > 0);
  };

  const showRightScroll = () => {
    setShowRightScroll(
      fileHolder.current &&
      fileHolder.current.offsetWidth + fileHolder.current.scrollLeft <
      fileHolder.current.scrollWidth
    );
  };

  const srcollFileHolder = () => {
    showLeftSroll();
    showRightScroll();
  };

  useEffect(() => {
    setTimeout(() => {
      if (fileHolder.current) srcollFileHolder();
    }, 50);
  }, [fileHolder]);


  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const {
    error,
    data = { warehouses: {} },
    isSuccess,
    refetch,
    isFetching,
  } = useQuery(
    ["SINGLE_WAREHOUSE_NAV", { wareshouseLocal }],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/single/${wareshouseLocal?.whID}`,
        "GET"
      ),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  // console.log(data?.warehouses);

  const items = useMemo(() => {
    const navItems = [
      {
        name: "Inventory Entry",
        to: "inventry-entry",
      },
      {
        name: "Item Issuer",
        to: "item-issuer",
      },

      {
        name: "Receive Item",
        to: "receive-item",
      },
      wareshouseLocal?.cater !== "Assets" &&
      wareshouseLocal?.cater !== "non-stock" && {
        name: "Inventory by Serial Number",
        to: "items-serial-details",
      },
      // wareshouseLocal?.cater === "Assets" && {
      //   name: "Maintenance",
      //   to: "maintance",
      // },
      wareshouseLocal?.cater !== "Assets" &&
      wareshouseLocal?.cater !== "non-stock" && {
        name: "Plant Damage Recorder",
        to: "plant-damages",
      },
      {
        name: "Damage/Return Recorder",
        to: "damages-return-recorder",
      },
      {
        name: "Purchase History",
        to: "purchase-history",
      },
      data?.warehouses?.Warehouse_Type === "Storage" && {
        name: "Issue History",
        to: "issue-history",
      },
      data?.warehouses?.Warehouse_Type === "Storage" && {
        name: "Return History",
        to: "return-history",
      },
      wareshouseLocal?.cater === "Assets" &&
      wareshouseLocal?.cater !== "non-stock" && {
        name: "Assets Register",
        to: "assets-register",
      },
      wareshouseLocal?.cater === "Stock" && {
        name: "Create Invoice",
        to: "create-invoice",
      },
      wareshouseLocal?.cater === "Stock" && {
        name: "Sales Analysis",
        to: "sales-analysis",
      },
      wareshouseLocal?.cater === "Stock" && {
        name: "Sale by Invoice",
        to: "sale-by-invoice",
      },
      {
        name: "Item Below Reorder Level",
        to: "out-of-stock",
      },
      {
        name: "Expiry Report",
        to: "expiry-report",
      },
      {
        name: "Stock Count",
        to: "stock-counts",
      },
      {
        name: "Internal Stock Received",
        to: "warehouse-internal-stock-received",
      },
      {
        name: "Stock Monitor",
        to: "stock-records",
      },
      {
        name: "Inventory Analysis",
        to: "inventory-analysis-warehouse",
      },
    ];


    // return this get root

    return getRootRoles({
      navItems,
      parent: "Warehouse",
    });
  }, [wareshouseLocal?.cater, data?.warehouses]);

  return (
    <div className="tabs-holder">
      {isShowLeftSroll && (
        <button
          className="btn slider-btn left"
          onClick={() => (fileHolder.current.scrollLeft -= 500)}
        >
          <ChevronLeftIcon />
        </button>
      )}
      <div
        ref={fileHolder}
        onScroll={(e) => srcollFileHolder(e)}
        className="dashboard-tabs innerpage-tabs px-4"
      >
        <ul className="nav nav-tabs">
          {items.map((item, index) => (
            <li key={index} className="nav-item">
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>{" "}
      {isShowRightSroll && (
        <button
          className="btn slider-btn right"
          onClick={() => (fileHolder.current.scrollLeft += 500)}
        >
          <ChevronRightIcon />
        </button>
      )}
    </div>
  );
}
